<template>
  <v-card
      class="pt-0 pb-0"
      style="border:none"
    >
      <v-card-title
        class="pl-0 pt-2 font-weight-light"
        style="position:relative;left:-40px"
      >
        National Voice : 12mth Call Count & Duration
      </v-card-title>
        <apexchart
          :options="chartOptions"
          :series="series"
          type="area"
          height="320"
          class="pl-1 pr-1"
        />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import _ from 'lodash'
  import moment from 'moment'

  export default {
    name: 'DashboardHero12MonthsVoiceUsage',
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        domesticVoiceTotal12Months: 'domesticVoiceTotal12Months',
      }),
    },
    watch: {
      domesticVoiceTotal12Months: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateDomesticVoice12MonthsTotal()
        },
      },
    },
    data () {
      return {
        series: [{// defined below
        }],

        chartOptions: {
          colors: ['#FBAA39', '#D538FC'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
            width: [2, 2],
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: [0.7, 0.7],
              opacityTo: [0.5, 0.5],
              stops: [0, 100],
              colorStops: [],
            },
          },
          markers: {
            size: 5,
            style: 'hollow',
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.8,
            strokeDashArray: 0,
            fillOpacity: 1,
            sizeOffset: 1,
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
          },
          yaxis: [
            {
              seriesName: 'Call Count',
              // axisTicks: {
              //   show: true,
              // },
              // axisBorder: {
              //   show: true,
              // },
              title: {
                text: 'Call Count',
                offsetX: 0,
                style: {
                  color: '#FBAA39',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
              tooltip: {
                enabled: false,
              },
            },

            {
              seriesName: 'Call Duration',
              opposite: true,
              // axisTicks: {
              //   show: true,
              // },
              // axisBorder: {
              //   show: true,
              // },

              title: {
                text: 'Call Duration (Min)',
                offsetX: 0,
                style: {
                  color: '#EA349D',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],
          tooltip: {
            followCursor: true,
            //             fixed: {
            //               enabled: true,
            //               position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            //               offsetY: 0,
            //               offsetX: 0,
            //             },
            x: {
              format: 'MMM yyyy',
            },
            // theme: 'dark',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          legend: {
            show: false,
            // horizontalAlign: 'left',
            // offsetX: 40,
          },
        },
      }
    },
    mounted () {
      console.log('DashboardHero12monthsDataUsage mounted')
      if (this.domesticVoiceTotal12Months.series[0]) {
        this.updateDomesticVoice12MonthsTotal()
      }
    },
    methods: {
      updateDomesticVoice12MonthsTotal () {
        console.log('updateDomesticVoice12MonthsTotal () data changed ', this.domesticVoiceTotal12Months.series[0].data)
        const minDate = moment(this.customerInfo.earliest + '01 +0000', 'YYYYMMDD Z').subtract(2, 'months') // minus 5 days
        const maxDate = moment(this.customerInfo.latest + '01 +0000', 'YYYYMMDD Z').add(2, 'months') // add 5 days
        const min = minDate.format('YYYYMM')
        const max = maxDate.format('YYYYMM')
        //
        // const min = moment(this.customerInfo.earliest + '01 +0000', 'YYYYMMDD Z')
        // const max = moment(this.customerInfo.latest + '01 +0000', 'YYYYMMDD Z')
        // this.chartOptions = {
        //   xaxis: {
        //     min: min,
        //     max: max,
        //   },
        // }

        this.series = [{
                         name: 'Call Count',
                         type: 'line',
                         data: this.domesticVoiceTotal12Months.series[0].data,
                       },
                       {
                         name: 'Call Duration (Min)',
                         type: 'line',
                         data: this.domesticVoiceTotal12Months.series[1].data,
                       },
        ]
      },

    },
  }
</script>

<style >
  .apexcharts-tooltip {
    background: #f3f3f3;
    color: black;
  }
</style>
