<template>
  <v-card
      class="pt-0 pb-0"
      style="border:none"
  >
    <v-card-title
      class="pl-0 pt-2 font-weight-light"
      style="position:relative;left:-40px"
    >
      12mth National Data Usage
    </v-card-title>
      <apexchart
        :options="chartOptions"
        :series="series"
        height="320"
        class="pl-1 pr-1"
      />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import _ from 'lodash'
  import moment from 'moment'
  import Utils from '@/api/util'

  export default {
    name: 'DashboardHero12MonthsDataUsage',
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        domesticDataTotal12Months: 'domesticDataTotal12Months',
      }),
    },
    watch: {
      domesticDataTotal12Months: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateDomesticData12MonthsTotal()
        },
      },
    },
    data () {
      return {
        series: [{// defined below
        }],

        chartOptions: {
          colors: ['#42B3F4', '#E91E63'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },

          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: [2, 0.3],
          },
          // stroke: {
          //   width: [1, 1, 4],
          // },
          plotOptions: {
            bar: {
              columnWidth: '40%',
            },
          },

          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: [0.7, 0.8],
              opacityTo: [0.4, 0.6],
              stops: [0, 100],
              colorStops: [],
            },
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
            axisBorder: {
              show: true,
              color: '#B2B2B2',
            },
          },

          yaxis: [
            {
              seriesName: 'Data Usage',
              // axisTicks: {
              //   show: true,
              // },
              // axisBorder: {
              //   show: true,
              //                color: '#008FFB',
              // },
              labels: {
                style: {
                  color: '#4D4D4D',
                  fontSize: '11px',
                },
                formatter: function (value) {
                  return Utils.readableBytes(value)
                },
              },
              title: {
                text: 'Data Usage (Mb)',
                offsetX: 0,
                style: {
                  color: '#42B3F4',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },

            {
              seriesName: 'Excess',
              opposite: true,
              // axisTicks: {
              //   show: true,
              // },
              // axisBorder: {
              //   show: true,
              //   // color: '#00E396',
              // },
              labels: {
                style: {
                  color: '#00E396',
                },
                formatter: function (value) {
                  return Utils.formatCurrency(value)
                },
              },
              title: {
                text: 'Excess ($)',
                offsetX: 0,
                style: {
                  color: '#E91E63',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],

          tooltip: {
            followCursor: true,
            x: {
              format: 'MMM yyyy',
            },
            // theme: 'dark',
          },

          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },

          legend: {
            show: false,
            // horizontalAlign: 'left',
            // offsetX: 40,
          },
        },
      }
    },
    mounted () {
      console.log('DashboardHero12monthsDataUsage mounted')
      if (this.domesticDataTotal12Months.series[0]) {
        this.updateDomesticData12MonthsTotal()
      }
    },
    methods: {
      updateDomesticData12MonthsTotal () {
        // console.log('updateDomesticData12MonthsTotal () data changed  latest ' + this.customerInfo.latest)
        // console.log('updateDomesticData12MonthsTotal () data changed ', this.domesticDataTotal12Months.series[0].data)
        const minDate = moment(this.customerInfo.earliest + '01 +0000', 'YYYYMMDD Z').subtract(2, 'months') // minus 5 days
        const maxDate = moment(this.customerInfo.latest + '01 +0000', 'YYYYMMDD Z').add(2, 'months') // add 5 days
        const min = minDate.format('YYYYMM')
        const max = maxDate.format('YYYYMM')
        // console.log('updateDomesticData12MonthsTotal () data changed ', { min: min, max: max })
        this.series = [{
                         name: 'Data Usage (Mb)',
                         type: 'area',
                         data: this.domesticDataTotal12Months.series[0].data,
                       },
                       {
                         name: 'Data Charges ($)',
                         type: 'bar',
                         data: this.domesticDataTotal12Months.series[1].data,
                       },
        ]
      },

    },
  }
</script>

<style >
  .apexcharts-tooltip {
    background: #f3f3f3;
    color: black;
  }
</style>
