<template>
  <v-container
    id="dashboard"
    fluid
  >
    <v-row>
      <v-col
        cols="6"
        sm="6"
        lg="6"
      >
        <v-list>
          <v-list-item-group
            v-model="selectedMake"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in equipmentSummary"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.deviceName" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        lg="6"
      >
        <apexchart
          type="radialBar"
          :options="deviceChartOptions"
          :series="devicesSeries"
        />
        <apexchart
          type="radialBar"
          :options="activityChartOptions"
          :series="activitySeries"
        />
        <apexchart
          type="radialBar"
          :options="billChartOptions"
          :series="billSeries"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { mapGetters } from 'vuex'
  import store from '@/store'
  import Utils from '@/api/util'

  export default {
    name: 'DashboardEquipmentSummary',
    // equipmentSummary: 'equipmentSummaryDevice',
    props: {
      equipmentSummary: {
        type: Array,
      },
    },
    data () {
      return {
        selectedMake: 0,
        selectedModel: 0,
        selectedDevice: 0,
        activityChartOptions: {
          title: {
            text: 'Activity',
            align: 'center',
            offsetX: 0,
            offsetY: 0,
          },
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: '#e7e7e7',
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  offsetY: 0,
                  show: true,
                  color: '#888',
                  fontSize: '17px',
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + '%'
                  },
                  offsetY: false,
                  color: '#111',
                  fontSize: '18px',
                  show: false,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91],
            },
          },
          labels: ['Percent'],
        },
        billChartOptions: {
          title: {
            text: 'Bill',
            align: 'center',
            offsetX: 0,
            offsetY: 0,
          },
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: '#e7e7e7',
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  offsetY: 0,
                  show: true,
                  color: '#888',
                  fontSize: '17px',
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + '%'
                  },
                  offsetY: false,
                  color: '#111',
                  fontSize: '18px',
                  show: false,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91],
            },
          },
          labels: ['Percent'],
        },
        deviceChartOptions: {
          title: {
            text: 'Devices',
            align: 'center',
            offsetX: 0,
            offsetY: 0,
          },
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true,
            },
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: '#e7e7e7',
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2,
                },
              },
              dataLabels: {
                name: {
                  offsetY: 0,
                  show: true,
                  color: '#888',
                  fontSize: '17px',
                },
                value: {
                  formatter: function (val) {
                    return parseInt(val) + '%'
                  },
                  offsetY: 0,
                  color: '#111',
                  fontSize: '18px',
                  show: false,
                },
              },
            },
          },
          grid: {
            padding: {
              top: -10,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91],
            },
          },
          labels: ['Percent'],
        },
        deviceSummaryHeaders: [
          {
            sortable: false,
            text: 'Make',
            value: 'make',
            align: 'right',
          },
        ],
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('dashboard', {
        refreshRequired: 'refreshRequired',
        // equipmentSummary: 'equipmentSummaryDevice',
      }),
      activitySeries () {
        // try to get the activity count and divide by total
        console.log('selectedMake', this.selectedMake)
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.activity / device.activityTotal * 100)
        return [percent]
      },
      billSeries () {
        // try to get the activity count and divide by total
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.bill / device.billTotal * 100)
        return [percent]
      },
      devicesSeries () {
        // try to get the activity count and divide by total
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.devices / device.devicesTotal * 100)
        return [percent]
      },
    },
    watch: {
      refreshRequired: {
        handler () {
          console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
        },
      },
      selectedMake: {
        handler () {
          this.updateDashboard(this.selectedMake)
        },
      },
      equipmentSummary: {
        handler () {
          console.log('watch equipmentSummary updated ', this.equipmentSummary)
          this.updateDashboard(this.selectedMake)
        },
      },
    },
    mounted () {
      console.log('DashboardEquipmentDeviceSummaryChart.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
      this.refreshList()
    },
    methods: {
      routeToServiceNumberDetail () {
        // this.$router.push({ path: '/dashboard' })
        // this.$router.push('dashboard')
      },
      refreshList () { // refresh the list of data
        // group by device eg APPLE,
        // get the total of billTotal, active, qty
        console.log('equipment summary ', this.equipmentSummary)
        this.selectedMake = 0
        this.updateDashboard(this.selectedMake)
      },
      updateDashboard (equipmentNo) {
        console.log('equipment name ', equipmentNo)
        let device = this.equipmentSummary[equipmentNo]
        this.activityChartOptions = {
          ...this.activityChartOptions,
          ...{
            labels: [Utils.formatNumber(device.activity)],
          },
        }
        this.billChartOptions = {
          ...this.billChartOptions,
          ...{
            labels: [Utils.formatCurrency(device.bill)],
          },
        }
        this.deviceChartOptions = {
          ...this.deviceChartOptions,
          ...{
            labels: [Utils.formatNumber(device.devices)],
          },
        }
        // this.selectedMake = equipment
      },
    },
  }
</script>

<style scoped>
</style>
