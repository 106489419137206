<template>
  <v-container
    id="dashboard"
    fluid
    class="pa-0"
  >
    <v-data-table
      :headers="deviceSummaryHeaders"
      :items="equipmentSummary"
      :hide-default-footer="true"
      dense
      class="text--medium"
    >
      <template slot="no-data">
        Loading equipment summary
      </template>
      <template v-slot:item.devices="{ item }">
        <span>{{formatNumber(item.devices)}}</span>
      </template>
      <template v-slot:item.activity="{ item }">
        <span>{{formatNumber(item.activity)}}</span>
      </template>
      <template v-slot:item.bill="{ item }">
        <span>{{formatCurrency(item.bill)}}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

  import { mapGetters } from 'vuex'
  import store from '@/store'
  import Utils from '@/api/util'

  export default {
    name: 'DashboardEquipmentSummary',
    // equipmentSummary: 'equipmentSummaryDevice',
    props: {
      equipmentSummary: {
        type: Array,
      },
    },
    data () {
      return {
        selectedMake: 0,
        selectedModel: 0,
        selectedDevice: 0,
        deviceSummaryHeaders: [
          {
            sortable: false,
            text: '',
            value: 'deviceName',
            align: 'left',
          },
          {
            sortable: false,
            text: 'Count',
            value: 'devices',
            align: 'right',
          },
          {
            sortable: false,
            text: 'In Use',
            value: 'activity',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Total Cost',
            value: 'bill',
            align: 'right',
          },
        ],
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('dashboard', {
        refreshRequired: 'refreshRequired',
        // equipmentSummary: 'equipmentSummaryDevice',
      }),
      activitySeries () {
        // try to get the activity count and divide by total
        // console.log('selectedMake', this.selectedMake)
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.activity / device.activityTotal * 100)
        return [percent]
      },
      billSeries () {
        // try to get the activity count and divide by total
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.bill / device.billTotal * 100)
        return [percent]
      },
      devicesSeries () {
        // try to get the activity count and divide by total
        let device = this.equipmentSummary[this.selectedMake]
        let percent = Math.floor(device.devices / device.devicesTotal * 100)
        return [percent]
      },
    },
    watch: {
      refreshRequired: {
        handler () {
          // console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
        },
      },
      selectedMake: {
        handler () {
        },
      },
      equipmentSummary: {
        handler () {
          // console.log('watch equipmentSummary updated ', this.equipmentSummary)
        },
      },
    },
    mounted () {
      console.log('DashboardEquipmentDeviceSummaryChart.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
      this.refreshList()
    },
    methods: {
      routeToServiceNumberDetail () {
        // this.$router.push({ path: '/dashboard' })
        // this.$router.push('dashboard')
      },
      refreshList () { // refresh the list of data
        // group by device eg APPLE,
        // get the total of billTotal, active, qty
        // console.log('equipment summary ', this.equipmentSummary)
        this.selectedMake = 0
        // this.updateDashboard(this.selectedMake)
      },
      formatCurrency (value) {
        return Utils.formatCurrency(value)
      },
      formatNumber (value) {
        return Utils.formatNumber(value)
      },
    },
  }
</script>

<style scoped>

.CursorPointer {
    cursor: pointer;
  }
  .CursorPointer:hover {color:teal;
  }
</style>
