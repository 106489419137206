<template>
  <v-container
    id="dashboard"
    fluid
    class="pa-0"
  >
    <v-data-table
      :headers="planListHeaders"
      :items="sortedPlanList"
      dense
      class="text--medium"
    >
      <template slot="no-data">
        No plan fee
      </template>
      <template v-slot:item.qty="{ item }">
        <span>{{formatNumber(item.qty)}}</span>
      </template>
      <template v-slot:item.fee="{ item }">
        <span>{{formatCurrency(item.fee)}}</span>
      </template>
      <template v-slot:item.total="{ item }">
        <span>{{formatCurrency(item.total)}}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

  import { mapGetters } from 'vuex'
  import store from '@/store'
  import Utils from '@/api/util'

  export default {
    name: 'DashboardPlanDetail',
    props: {
      planList: {
        type: Array,
      },
    },
    data () {
      return {
        planListHeaders: [
          {
            sortable: false,
            text: '',
            value: 'planName',
            align: 'left',
          },
          {
            sortable: false,
            text: 'COUNT',
            value: 'qty',
            align: 'right',
          },
          {
            sortable: false,
            text: 'FEE($)',
            value: 'fee',
            align: 'right',
          },
          {
            sortable: false,
            text: 'TOTAL($))',
            value: 'total',
            align: 'right',
          },
        ],
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
      }),
      ...mapGetters('dashboard', {
        refreshRequired: 'refreshRequired',
        // equipmentSummary: 'equipmentSummaryDevice',
      }),
      sortedPlanList () {
        let pl = [...this.planList]
        pl.sort((a, b) => b.total - a.total) // sort by desc qty
        // console.log('sortedPlanList ', pl)
        return pl
      },
    },
    watch: {
      refreshRequired: {
        handler () {
          console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
        },
      },
    },
    mounted () {
      console.log('DashboardEquipmentDeviceSummaryChart.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
    },
    methods: {
      formatCurrency (value) {
        return Utils.formatCurrency(value)
      },
      formatNumber (value) {
        return Utils.formatNumber(value)
      },
    },
  }
</script>

<style scoped>

.CursorPointer {
    cursor: pointer;
  }
  .CursorPointer:hover {color:teal;
  }
</style>
