<template>
    <v-card
          class="card-tabs pa-3"
          align="center"
          height="100%"
        >
          <span class="v-card__title font-weight-light pa-2">Plan Detail</span>
      <v-tabs
        v-model="tabs"
        class="pa-0"
        slider-color="pink"
        background-color="transparent"
        align="center"
      >
        <v-tab class="mr-1">
          FLEXI PLAN
        </v-tab>
<!--        <v-tab class="mr-1">-->
<!--          SHAREABLE PLAN-->
<!--        </v-tab>-->
<!--        <v-tab class="mr-1">-->
<!--          NON SHAREABLE PLAN-->
<!--        </v-tab>-->
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <dashboard-plan-detail :plan-list="flexiPlanSummaryList" />
        </v-tab-item>
<!--        <v-tab-item>-->
<!--          <dashboard-plan-detail :plan-list="shareablePlanSummaryList" />-->
<!--        </v-tab-item>-->
<!--        <v-tab-item>-->
<!--          <dashboard-plan-detail :plan-list="nonShareablePlanSummaryList" />-->
<!--        </v-tab-item>-->
      </v-tabs-items>
    </v-card>

</template>

<script>

  import { mapGetters } from 'vuex'
  import DashboardPlanDetail from './DashboardPlanDetail'
  export default {
    name: 'DashboardPlanSummary',
    components: {
      DashboardPlanDetail,
    },
    data () {
      return {
        tabs: 0,
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        flexiPlanSummaryList: 'flexiPlanSummaryList',
        // shareablePlanSummaryList: 'shareablePlanSummaryList',
        // nonShareablePlanSummaryList: 'nonShareablePlanSummaryList',
      }),
    },
    watch: {
      refreshRequired: {
        handler () {
          console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
        },
      },
    },
    mounted () {
      console.log('DashboardPlanSummary.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
      // this.refreshList()
    },
    methods: {
    },
  }
</script>

<style scoped>
  /* style the svg rect */
  .ct-zoom-rect {
    fill: rgba(200, 100, 100, 0.3);
    stroke: red;
  }

</style>
