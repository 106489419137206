<template>
    <v-card
          class="card-tabs pa-3"
          align="center"
          height="100%"
        >
          <span class="v-card__title font-weight-light pa-2">Equipment</span>
      <v-tabs
        v-model="tabs"
        class="pa-0"
        slider-color="pink"
        background-color="transparent"
        align="center"
      >
        <v-tab class="mr-1">
          Type
        </v-tab>
        <v-tab class="mr-1">
          Make
        </v-tab>
        <v-tab class="mr-1">
          Name
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <dashboard-equipment-detail :equipment-summary="equipmentSummaryType" />
        </v-tab-item>
        <v-tab-item>
          <dashboard-equipment-detail :equipment-summary="equipmentSummaryMake" />
        </v-tab-item>
        <v-tab-item>
          <dashboard-equipment-detail :equipment-summary="equipmentSummaryModel" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

</template>

<script>

  import { mapGetters } from 'vuex'
  import DashboardEquipmentDeviceSummaryChart from './apex/DashboardEquipmentDeviceSummaryChart'
  import DashboardEquipmentDetail from './DashboardEquipmentDetail'
  export default {
    name: 'DashboardEquipmentSummary',
    components: {
      DashboardEquipmentDetail,
    },
    data () {
      return {
        tabs: 0,
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        refreshRequired: 'refreshRequired',
        equipmentSummaryType: 'equipmentSummaryType',
        equipmentSummaryMake: 'equipmentSummaryMake',
        equipmentSummaryModel: 'equipmentSummaryModel',
      }),
    },
    watch: {
      refreshRequired: {
        handler () {
          console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
        },
      },
      selectedMake: {
        handler () {
          // this.updateDashboard(this.selectedMake)
        },
      },
    },
    mounted () {
      console.log('DashboardEquipmentSummary.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
      // this.refreshList()
    },
    methods: {
    },
  }
</script>

<style scoped>
  /* style the svg rect */
  .ct-zoom-rect {
    fill: rgba(200, 100, 100, 0.3);
    stroke: red;
  }

</style>
