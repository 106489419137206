
<template>
  <v-container
    id="dashboard"
    fluid
    class="pt-0"
    style="min-width:1366px;max-width:1920px;"
  >
    <v-row class="pt-1">
      <v-col
        cols="12"
        sm="8"
        lg="8"
        class="pt-0 pb-0"
      >
        <v-row class="pt-0 pb-0">
          <v-col
            cols="12"
            sm="6"
            lg="6"
            class="pt-3 pb-0"
          >
            <v-card class="pa-3">
              <v-simple-table class="mb-1">
                <tr>
                  <td class="text--header">
                    
                  </td>
                </tr>
                <tr>
                  <td
                    class="title"
                    width="100%"
                  >
                    {{ companyName }}
                  </td>
                </tr>
              </v-simple-table>
              <v-divider
                class="mx-auto"
                width="100%"
              />
              <v-simple-table class="mt-1">
                <tr>
                  <td colspan="2">
                    <span class="text--header pl-1">UCIDN:</span> <span class="text--medium pl-1">{{ ucidn }}</span>
                  </td>
                  <td
                    colspan="2"
                    class="text-right"
                  >
                    <span class="text--header pl-1">CIDN Count:</span> <span class="text--medium pl-1">{{ cidnCount }}</span>
                  </td>
                </tr>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="6"
            class="pt-3"
          >
            <v-card class="pa-3">
              <v-simple-table class="mb-1">
                <tr>
                  <td
                    colspan="2"
                    class="text--header"
                    width="100%"
                  >
                    Period
                  </td>
                  <td class="text-right">
                    <span
                      class="text--header"
                      width="60px"
                    >Accounts</span>
                  </td>
                  <td
                    rowspan="2"
                    class="pl-2 pt-0 pb-0"
                  >
                    <span class="text--large">{{ acctCount }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    class="title"
                    style="padding-left:10px"
                  >
                    <span class="title">{{ earliestMonth }} to {{ latestMonth }}</span>
                  </td>
                </tr>
              </v-simple-table>
              <v-divider
                class="mx-auto"
                width="100%"
              />
              <v-simple-table class="mt-1">
                <tr>
                  <td colspan="2">
                    <span class="text--header pl-1">Total Charges:</span> <span class="text--medium pl-1">{{ billTotal }}</span>
                  </td>
                  <td
                    colspan="2"
                    class="text-right"
                  >
                    <span class="text--header pr-1">Service Count:</span>  <span class="text--medium pl-1">{{ serviceCount }}</span>
                  </td>
                </tr>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="pt-0 pb-0 mt-0">
          <v-col
            cols="12"
            sm="12"
            lg="12"
            class="pt-0 pb-0 mt-0"
          >
          <v-card class="pl-3 pr-3 pt-0 pb-0">
            <v-carousel
              hide-delimiters
              height="100%"
            >
              <v-carousel-item light>
                    <v-col class="pl-12 pr-12 pt-0 pb-0">
                      <dashboard-hero12-months-bill-total />
                    </v-col>
              </v-carousel-item>
              <v-carousel-item light>
                    <v-col class="pl-12 pr-12 pt-0 pb-0">
                      <dashboard-hero12-months-data-usage />
                    </v-col>
              </v-carousel-item>
              <v-carousel-item light>
                    <v-col class="pl-12 pr-12 pt-0 pb-0">
                      <dashboard-hero12-months-voice-usage />
                    </v-col>
              </v-carousel-item>
            </v-carousel>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="4"
        lg="4"
        class="pt-0 pb-3"
      >
        <dashboard-current-month-summary />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="4"
        sm="4"
      >
        <dashboard-equipment-summary />
      </v-col>

      <v-col
        cols="12"
        lg="4"
        sm="4"
      >
        <dashboard-plan-summary />
      </v-col>

      <v-col
        cols="12"
        lg="4"
        sm="4"
      >
        <v-card
          class="card-tabs pa-3 OnHover"
          height="100%"
        >
          <span class="v-card__title font-weight-light pa-2">Top Tens</span>

          <v-tabs
            v-model="tabs"
            class="pa-0"
            slider-color="pink"
            background-color="transparent"
            align="center"
          >
            <v-tab class="mr-1">
              Total
            </v-tab>
            <v-tab class="mr-1">
              Call Count
            </v-tab>
            <v-tab class="mr-1">
              Nat. Data (Mb)
            </v-tab>
            <v-tab>Intnl Data (Mb)</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-data-table
                :headers="topTenHeaders"
                :items="topTenDomesticBillTotal"
                :items-per-page="10"
                dense
                hide-default-footer
                class="blitztable"
              >
                <template v-slot:item.sn="{ item }">
                  <router-link
                    :to="{ path: '/pages/serviceNumberPage/'+ item.sn }"
                  >
                    {{ item.sn }}
                  </router-link>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span>{{formatCurrency(item.qty)}}</span>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="topTenHeaders"
                :items="topTenDomesticVoiceCall"
                :items-per-page="10"
                dense
                hide-default-footer
                class="blitztable"
              >
                <template slot="no-data">
                  Loading top ten
                </template>
                <template v-slot:item.sn="{ item }">
                  <router-link
                    :to="{ path: '/pages/serviceNumberPage/'+ item.sn }"
                  >
                    {{ item.sn }}
                  </router-link>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span>{{formatNumber(item.qty)}}</span>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="topTenHeaders"
                :items="topTenDomesticUsage"
                :items-per-page="10"
                dense
                hide-default-footer
                class="blitztable"
              >
                <template v-slot:item.sn="{ item }">
                  <router-link
                    :to="{ path: '/pages/serviceNumberPage/'+ item.sn }"
                  >
                    {{ item.sn }}
                  </router-link>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span>{{formatBytes(item.qty)}}</span>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="topTenHeaders"
                :items="topTenOverseasCall"
                :items-per-page="10"
                dense
                hide-default-footer
              >
                <template
                  v-slot:item.sn="{ item }"
                >
                  <router-link
                    :to="{ path: '/pages/serviceNumberPage/'+ item.sn }"
                  >
                    {{ item.sn }}
                  </router-link>
                </template>
                <template v-slot:item.qty="{ item }">
                  <span>{{formatBytes(item.qty)}}</span>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import store from '@/store'
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import * as ChartistZoom from 'chartist-plugin-zoom' // do not uncomment, it is need by the chart plugins
  import * as ChartistTooltips from 'chartist-plugin-tooltips-updated' // do not uncomment, it is need by the chart plugins
  import DashboardHero12MonthsDataUsage from './components/apex/DashboardHero12MonthsDataUsage'
  import DashboardHero12MonthsBillTotal from './components/apex/DashboardHero12MonthsBillTotal'
  import DashboardHero12MonthsVoiceUsage from './components/apex/DashboardHero12MonthsVoiceUsage'
  import DashboardCurrentMonthSummary from './components/DashboardCurrentMonthSummary'
  import DashboardEquipmentSummary from './components/DashboardEquipmentSummary'
  import DashboardPlanSummary from './components/DashboardPlanSummary'
  import Utils from '@/api/util'

  export default {
    components: {
      DashboardHero12MonthsDataUsage,
      DashboardHero12MonthsBillTotal,
      DashboardHero12MonthsVoiceUsage,
      DashboardCurrentMonthSummary,
      DashboardEquipmentSummary,
      DashboardPlanSummary,
    },
    data () {
      return {
        colors: [
          'primary',
          'secondary',
          'yellow darken-2',
          'red',
          'orange',
        ],
        storeBillTotalZoomIcon: false,
        resetBillTotal: function () {},
        mobileCountTotalActive: 'Total Active 0',
        currentMonthIncreasePercent: '0%',
        currentMonthIncreaseIcon: 'mdi-arrow-up',
        domesticDataCountLabel: 'Count 0',
        searchEquipment: '',
        overseasDataCountLabel: 'Travelled 0',
        topTenHeaders: [
          {
            sortable: false,
            text: 'Mobile',
            value: 'sn',
          },
          {
            sortable: false,
            text: 'Customer Name',
            value: 'userName',
          },
          {
            sortable: false,
            text: 'Value',
            value: 'qty',
            align: 'right',
          },

        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        refreshRequired: 'refreshRequired',
        acctCount: 'acctCount',
        cidnCount: 'cidnCount',
        mobileCount: 'mobileCount',
        activeCIDNCount: 'activeCIDNCount',
        billTotal12Months: 'billTotal12Months',
        domesticVoiceTotal12Months: 'domesticVoiceTotal12Months',
        domesticDataTotal12Months: 'domesticDataTotal12Months',
        deviceSummaryList: 'deviceSummaryList',
        topTenLoading: 'topTenLoading',
        topTenDomesticCall: 'topTenDomesticCall',
        topTenDomesticUsage: 'topTenDomesticUsage',
        topTenDomesticBillTotal: 'topTenDomesticBillTotal',
        topTenDomesticVoiceCall: 'topTenDomesticVoiceCall',
        topTenDomesticVoiceDuration: 'topTenDomesticVoiceDuration',
        topTenOverseasCall: 'topTenOverseasCall',
      }),
      serviceCount: function () {
        return Utils.formatNumber(this.mobileCount)
      },
      earliestMonth: function () {
        const dateValue = moment(this.customerInfo.earliest + '01 +0000', 'YYYYMMDD Z')
        return dateValue.format('MMM YY')
      },
      latestMonth: function () {
        const dateValue = moment(this.customerInfo.latest + '01 +0000', 'YYYYMMDD Z')
        return dateValue.format('MMM YY')
      },
      companyName: function () {
        return this.customerInfo.customerName
      },
      billTotal: function () {
        // sum billTotal
        // console.log('billTotal12Months', this.billTotal12Months)
        if (this.billTotal12Months.series[0] && this.billTotal12Months.series[0].length > 0) {
          const billTotal = this.billTotal12Months.series[0].map(xy => xy.y).reduce((prev, cur) => prev + cur, 0.0)
          return Utils.formatCurrency(billTotal)
        } else {
          return ''
        }
      },
      ucidn: function () {
        return this.customerInfo.ucidn
      },
      blitzIcon: function () {
        return this.customerInfo.blitzIcon
      },
      blitzLogo: function () {
        return this.customerInfo.blitzLogo
      },
      navbarTitle: function () {
        return this.customerInfo.navbarTitle
      },
    },
    watch: {
      refreshRequired: {
        handler () {
          console.log('watch refreshRequired change detected!! refreshRequired is ' + this.refreshRequired)
          // store.dispatch('app/setProgress', true)
          // store.dispatch('dashboard/loadData') // loadData will load data from pouch to vuex
          // store.dispatch('dashboard/loadTopTen')
        },

      },
      currentMonthIncrease: {
        handler () {
          if (this.currentMonthIncrease > 0) {
            this.currentMonthIncreaseIcon = 'mdi-arrow-up'
          } else if (this.currentMonthIncrease < 0) {
            this.currentMonthIncreaseIcon = 'mdi-arrow-down'
          } else {
            this.currentMonthIncreaseIcon = ''
          }
          this.currentMonthIncreasePercent = this.currentMonthIncrease + '%'
        },
      },
      domesticDataCount: {
        handler () {
          this.domesticDataCountLabel = 'Count ' + this.domesticDataCount
        },
      },
      overseasDataCount: {
        handler () {
          this.overseasDataCountLabel = 'Travelled ' + this.overseasDataCount
        },
      },
    },
    mounted () {
      console.log('Dashboard.vue mounted refreshRequired ' + this.refreshRequired)
      // this.$gtag.event('mounted', { page: 'dashboard' })
      this.$gtag.pageview({
        page_path: '/dashboard',
      })

      if (this.refreshRequired) {
        store.dispatch('app/setProgress', true)
        store.dispatch('dashboard/loadData')
        store.dispatch('dashboard/loadTopTen')
      } else {
      }
    },
    methods: {
      routeToServiceNumberDetail () {
        // this.$router.push({ path: '/dashboard' })
        // this.$router.push('dashboard')
      },
      getColor (sn) {
        // if (calories > 400) return 'red'
        // else if (calories > 200) return 'orange'
        // else return 'green'
        return 'green'
      },
      formatCurrency (value) {
        return Utils.formatCurrency(value)
      },
      formatBytes (value) {
        return Utils.readableBytes(value)
      },
      formatNumber (value) {
        return Utils.formatNumber(value)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      storeBillTotalZoomReset (chart, resetFunction) {
        // document.getElementById('reset').style.display = 'inline'; // show the reset button...
        this.resetBillTotal = resetFunction
        this.storeBillTotalZoomIcon = true
        console.log('storeReset called ' + this.resetBillTotal)
      },
      resetBillTotalZoom () {
        this.resetBillTotal()
        this.storeBillTotalZoomIcon = false
        console.log('resetBillTotalZoom called ')
      },
    },
  }
</script>
<style scoped>
  /* style the svg rect */
  .ct-zoom-rect {
    fill: rgba(200, 100, 100, 0.3);
    stroke: red;
  }

</style>
