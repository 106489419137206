<template>
  <v-card
    class="pt-0 pb-0"
    style="border:none"
  >
    <v-card-title
      class="pl-0 pt-2 font-weight-light"
      style="position:relative;left:-40px"
    >
      12 Months Total ($)
    </v-card-title>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="series"
      height="330px"
      class="pl-1 pr-1"
    />
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import Utils from '@/api/util'

  export default {
    name: 'DashboardHero12MonthsBillTotal',
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('dashboard', {
        billTotal12Months: 'billTotal12Months', // refer to actions.js updateBillTotal12MonthChart
      }),
    },
    watch: {
      billTotal12Months: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateBillTotal12MonthsTotal()
        },
      },
    },
    data () {
      return {
        series: [],
        chartOptions: {
          colors: ['#E91E63'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.8,
              opacityTo: 0.6,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            width: 0.3,
          },
          plotOptions: {
            bar: {
              columnWidth: '50%',
            },
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return Utils.formatCurrency(value)
              },
            },
          },
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
      }
    },
    mounted () {
      console.log('DashboardHero12monthsBillTotal mounted')
      if (this.billTotal12Months.series[0]) {
        this.updateBillTotal12MonthsTotal()
      }
    },
    methods: {
      updateBillTotal12MonthsTotal () {
        console.log('updateBillTotal12MonthsTotal () data changed  latest ' + this.customerInfo.latest)
        const minDate = moment(this.customerInfo.earliest + '01 +0000', 'YYYYMMDD Z').subtract(2, 'months') // minus 5 days
        const maxDate = moment(this.customerInfo.latest + '01 +0000', 'YYYYMMDD Z').add(2, 'months') // add 5 days
        // this.chartOptions = {
        //   xaxis: {
        //     min: minDate.toDate(),
        //     max: maxDate.toDate(),
        //   },
        // }
        this.series = [{
          name: 'Total Charges',
          data: this.billTotal12Months.series[0],
          // data: chartData,
        },
        ]
      },

    },
  }
</script>

<style >
  .apexcharts-tooltip {
    background: #f3f3f3;
    color: black;
  }
  .apexcharts-Title {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }
</style>
