<template>
  <v-card
    class="pa-3"
    height="100%"
  >
    <v-simple-table class="mb-1">
      <tr>
        <td
          class="text--header"
          width="50%"
        >
          Current Period
        </td>
        <td class="text-right">
          <span class="text--header">Current Charges</span>
        </td>
      </tr>
      <tr>
        <td
          class="title"
          width="50%"
          style="padding-top:3px"
        >
          {{ currentMonthLabel }}
        </td>
        <td class="text-right">
          <span class="title">{{ currentMonthBill }}</span>
        </td>
      </tr>
    </v-simple-table>

    <v-divider
      class="mx-auto"
      width="100%"
    />

    <v-simple-table class="mt-1">
      <tr>
        <td><span class="text--header pr-1">In Use Count:</span>  <span class="text--medium pl-1">{{ activeCount }}</span></td>
        <td class="text-right">
          <span class="text--header pr-1">3 mth Avg.</span> <span class="text--medium pl-1">{{ billAvg3Months }}</span> <span class="text--medium pl-2">{{ currentMonthVariance.percent }} <v-icon
            size="medium"
            style="margin-top:-2px;margin-left:-3px"
            :color="resolveVarianceColor(currentMonthVariance.indicator)"
          >{{ resolveVarianceIcon(currentMonthVariance.indicator) }}</v-icon></span>
        </td>
      </tr>
    </v-simple-table>
    <v-simple-table class="dashboard">
      <tr>
        <td
          colspan="4"
          class="text-right"
        >
          <span class="text--header-title"><b>National Calls</b></span>
        </td>
      </tr>
      <tr>
        <td />
        <td
          class="text--header-sml"
          width="18%"
        >
          Count
        </td>
        <td
          class="text--header-sml"
          width="18%"
        >
          Variance
        </td>
        <td
          rowspan="4"
          width="36%"
          align="center"
          class="pl-2 pr-2"
        >
          <apexchart
            :options="callSparkLineOptions"
            :series="callSparkLine"
            min-width="120px"
            height="50px"
          />
        </td>
      </tr>
      <tr>
        <td class="text--header-sml">
          National Calls
        </td>
        <td class="text--medium">
          {{ callCount }}
        </td>
        <td class="text--medium">
          {{ callVariance.percent }} <v-icon
            size="large"
            style="margin-top:-2px;margin-left:-3px"
            :color="resolveVarianceColor(callVariance.indicator)"
          >
            {{ resolveVarianceIcon(callVariance.indicator) }}
          </v-icon>
        </td>
      </tr>
      <tr>
        <td class="text--header-sml">
          SMS Texts
        </td>
        <td class="text--medium">
          {{ smsCount }}
        </td>
        <td class="text--medium">
          {{ smsVariance.percent }} <v-icon
            size="large"
            style="margin-top:-2px;margin-left:-3px"
            :color="resolveVarianceColor(smsVariance.indicator)"
        >
            {{ resolveVarianceIcon(smsVariance.indicator) }}
          </v-icon>
        </td>
      </tr>
      <tr height="10px">
        <td colspan="3" />
      </tr>
    </v-simple-table>

    <v-simple-table class="dashboard">
      <tr>
        <td
          colspan="4"
          class="text-right"
        >
          <span class="text--header-title"><b>National Data</b></span>
        </td>
      </tr>
      <tr>
        <td class="text--header-sml">
          Total Data Usage
        </td>
        <td
          class="text--header-sml"
          width="18%"
        >
          Data Charges
        </td>
        <td
          class="text--header-sml"
          width="18%"
        >
          Variance
        </td>
        <td
          rowspan="4"
          width="36%"
          class="pl-2 pr-2"
          align="center"
        >
          <apexchart
            :options="dataSparkLineOptions"
            :series="dataSparkLine"
            min-width="120px"
            height="50px"
          />
        </td>
      </tr>
      <tr>
        <td class="text--medium">
          {{ domesticDataUsageSum }}
        </td>
        <td class="text--medium">
          {{ domesticDataExcessSum }}
        </td>
        <td class="text--medium">
          {{ domesticDataUsageVariance.percent }} <v-icon
            size="large"
            style="margin-top:-2px;margin-left:-3px"
            :color="resolveVarianceColor(domesticDataUsageVariance.indicator)"
          >
            {{ resolveVarianceIcon(domesticDataUsageVariance.indicator) }}
          </v-icon>
        </td>
      </tr>
      <!-- <tr>
        <td class="text--header-sml">
          Data Allowance
        </td>
        <td
          colspan="2"
          rowspan="2"
          class="text-center"
        >
          <span class="title"></span>
        </td>
      </tr>
      <tr>
        <td class="text--medium">
          {{ domesticDataExcessSum }}
        </td>
      </tr> -->
      <tr>
        <td class="text--header-sml">
          Total Data Allowance
        </td>
        <td
          colspan="2"
          rowspan="2"
          class="text-center"
        >
          <span class="title"></span>
        </td>
      </tr>
      <tr>
        <td class="text--medium">
          {{ dataAllowanceTotal }}
        </td>
      </tr>
    </v-simple-table>

    <v-simple-table class="dashboard">
      <tr>
        <td
          colspan="4"
          class="text-right"
        >
          <span class="text--header-title"><b>International Data</b></span>
        </td>
      </tr>
      <tr>
        <td />
        <td />
        <td />
        <td
          rowspan="4"
          width="36%"
          class="pl-2 pr-2"
          align="center"
        >
          <apexchart
            :options="overseasSparkLineOptions"
            :series="overseasSparkLine"
            min-width="120px"
            height="50px"
          />
        </td>
      </tr>
      <tr>
        <td class="text--header-sml">
          Intnl Data Usage
        </td>
        <td
          class="text--header-sml"
          width="18%"
        >
          Cost
        </td>
        <td
          class="text--header-sml"
          width="18%"
        >
          Variance
        </td>
      </tr>
      <tr>
        <td class="text--medium">
          {{ overseasDataUsageSum }}
        </td>
        <td class="text--medium">
          {{ overseasDataExcessSum }}
        </td>
        <td class="text--medium">
          {{ overseasDataUsageVariance.percent }} <v-icon
            size="large"
            style="margin-top:-2px;margin-left:-3px"
            :color="resolveVarianceColor(overseasDataUsageVariance.indicator)"
          >
            {{ resolveVarianceIcon(overseasDataUsageVariance.indicator) }}
          </v-icon>
        </td>
      </tr>
      <tr>
        <td colspan="3" />
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
  import store from '@/store'
  import { createNamespacedHelpers } from 'vuex'
  import _ from 'lodash'
  import moment from 'moment'
  import Utils from '@/api/util'

  const { mapGetters } = createNamespacedHelpers('dashboard')

  export default {
    name: 'DashboardCurrentMonthSummary',
    data () {
      return {
        callSparkLine: [],
        callSparkLineOptions: {
          colors: ['#FBAA39'],
          chart: {
            type: 'area',
            sparkline: {
              enabled: true,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.3,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            width: 1,
            curve: 'straight',
          },
          tooltip: {
            enabled: false,
          },
        },
        dataSparkLine: [],
        dataSparkLineOptions: {
          chart: {
            type: 'area',
            sparkline: {
              enabled: true,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.3,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            width: 1,
          },
          tooltip: {
            enabled: false,
          },
        },
        overseasSparkLine: [],
        overseasSparkLineOptions: {
          chart: {
            type: 'area',
            sparkline: {
              enabled: true,
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.3,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            width: 1,
          },
          tooltip: {
            enabled: false,
          },
        },

      }
    },
    computed: {
      ...mapGetters([
        'acctCount',
        'refreshRequired',
        'currentMonthTotal',
        'currentMonthLabel',
        'currentMonthIncrease',
        'currentMonthAvg3Months',
        'mobileCount',
        'currentMonthActive',
        'currentDomesticVoice',
        'currentDomesticData',
        'currentOverseasData',
        'dataAllowance',
      ]),
      activeCount: function () {
        return Utils.formatNumber(this.currentMonthActive)
      },
      billAvg3Months: function () {
        return Utils.formatCurrency(this.currentMonthAvg3Months)
      },
      currentMonthBill: function () {
        return Utils.formatCurrency(this.currentMonthTotal)
      },
      currentMonthVariance: function () {
        const variance = Utils.calculateVariance(this.currentMonthTotal, this.currentMonthAvg3Months)
        return variance
      },
      overseasDataUsageSum: function () {
        return Utils.readableBytes(this.currentOverseasData.overseasDataSum)
      },
      overseasDataExcessSum: function () {
        return Utils.formatCurrency(this.currentOverseasData.overseasDataExcessSum)
      },
      overseasDataUsageVariance: function () {
        const variance = Utils.calculateVariance(this.currentOverseasData.overseasDataSum, this.currentOverseasData.overseasDataAvg3Months)
        return variance
      },
      domesticDataUsageSum: function () {
        return Utils.readableBytes(this.currentDomesticData.domesticDataSum)
      },
      domesticDataExcessSum: function () {
        return Utils.formatCurrency(this.currentDomesticData.domesticDataExcessSum)
      },
      domesticDataUsageVariance: function () {
        const variance = Utils.calculateVariance(this.currentDomesticData.domesticDataSum, this.currentDomesticData.domesticDataAvg3Months)
        return variance
      },
      dataAllowanceTotal: function () {
        return Utils.readableBytes(this.dataAllowance)
      },

      callCount: function () {
        return Utils.formatNumber(this.currentDomesticVoice.callTotal)
      },
      callVariance: function () {
        const variance = Utils.calculateVariance(this.currentDomesticVoice.callTotal, this.currentDomesticVoice.callAvg3Months)
        return variance
      },
      smsCount: function () {
        return Utils.formatNumber(this.currentDomesticVoice.smsTotal)
      },
      smsVariance: function () {
        const variance = Utils.calculateVariance(this.currentDomesticVoice.smsTotal, this.currentDomesticVoice.smsAvg3Months)
        return variance
      },
      serviceCount: function () {
        return Utils.formatNumber(this.mobileCount)
      },
      serviceActive: function () {
        return Utils.formatNumber(this.currentMonthActive)
      },
    },
    watch: {
      currentDomesticVoice: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateCallSparkLine()
        },
      },
      currentDomesticData: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateDataSparkLine()
        },
      },
      currentOverseasData: {
        handler () { // this is only called when the page is first refresh as the other process updates vuex
          this.updateOverseasSparkLine()
        },
      },
    },
    mounted () {
      console.log('DashboardCurrentMonthSummary.vue mounted refreshRequired ' + this.refreshRequired)
      this.updateCallSparkLine()
      this.updateDataSparkLine()
      this.updateOverseasSparkLine()
    },
    methods: {
      resolveVarianceColor (varianceIndicator) {
        if (varianceIndicator === 'up') {
          return 'red'
        }
        if (varianceIndicator === 'down') {
          return 'green'
        }
        if (varianceIndicator === 'no-change') {
          return 'black'
        }
      },
      resolveVarianceIcon (varianceIndicator) {
        if (varianceIndicator === 'up') {
          return 'mdi-arrow-up'
        }
        if (varianceIndicator === 'down') {
          return 'mdi-arrow-down'
        }
        if (varianceIndicator === 'no-change') {
          return null
        }
      },
      updateCallSparkLine () {
        const sparkLineData = this.currentDomesticVoice.nationalCallSparkLineData
        if (!sparkLineData) return
        const sparkDataList = []
        sparkLineData.forEach((sparkData) => {
          sparkDataList.push({ x: sparkData.yearMonth, y: sparkData.call })
        })
        this.callSparkLine = [{
          name: 'National calls',
          data: sparkDataList,
        }]
      },
      updateDataSparkLine () {
        const sparkLineData = this.currentDomesticData.nationalDataSparkLineData
        if (!sparkLineData) return
        const sparkDataList = []
        sparkLineData.forEach((sparkData) => {
          sparkDataList.push({ x: sparkData.yearMonth, y: sparkData.usage })
        })
        this.dataSparkLine = [{
          name: 'National data',
          data: sparkDataList,
        }]
      },
      updateOverseasSparkLine () {
        const sparkLineData = this.currentOverseasData.overseasDataSparkLineData
        if (!sparkLineData) return
        const sparkDataList = []
        sparkLineData.forEach((sparkData) => {
          sparkDataList.push({ x: sparkData.yearMonth, y: sparkData.usage })
        })
        this.overseasSparkLine = [{
          name: 'Overseas data',
          data: sparkDataList,
        }]
      },

    },
  }
</script>

<style scoped>

</style>
